'use client';

import { createContext } from 'react';
import { Dictionary } from '../utils/i18n/dictionaries/dictionaries';

export const i18nContext = createContext<Dictionary>({} as Dictionary);

const DictionaryProvider = ({ children, dictionary }: { children: React.ReactNode; dictionary: Dictionary }) => {
  return <i18nContext.Provider value={dictionary}>{children}</i18nContext.Provider>;
};

export default DictionaryProvider;
